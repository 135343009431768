<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button :disabled="entity.status > 0 && entity.status != 4" v-shortkey="['ctrl', 's']" @shortkey="submit(1)" @click="submit(1)" class="md-raised md-primary"><span>G</span>ửi phê duyệt<md-tooltip>Gửi yêu cầu phê duyệt (Ctrl + L)</md-tooltip></md-button>
                        <md-button :disabled="entity.status > 0" v-shortkey="['ctrl', 'l']" @shortkey="submit(0)" @click="submit(0)" class="md-raised md-temp"><span>L</span>ưu tạm thời<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="form-body">
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.estimateCode.$error }">
                                <label for="estimateCode">Số dự toán</label>
                                <md-input disabled name="estimateCode" v-model="entity.estimateCode"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.estimateCode.required">Vui lòng nhập số dự toán</span>
                            </md-field>
                            <div class="form-control">
                                <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                                    <label>Người dự toán</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn người dự toán</span>
                                </md-autocomplete>
                                <md-button @click="openStaff()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm nhân viên</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        
                        <div class="col l-4 m-4 c-12">
                            <div class="row">
                                <div class="col l-5 m-5 c-12">
                                    <div class="form-control">
                                        <md-field :class="{'md-invalid': submitted && $v.entity.estimateType.$error }">
                                            <label for="estimateType">Dự toán</label>
                                            <md-select v-model="entity.estimateType" name="estimateType">
                                                <md-option :value="0">Loại dự toán</md-option>
                                                <md-option :value="1">Đăng kiểm</md-option>
                                            </md-select>
                                            <span class="md-error" v-if="submitted && !$v.entity.estimateType.isSelected">Vui lòng chọn loại dự toán</span>
                                        </md-field>
                                    </div>
                                </div>
                                <div class="col l-7 m-7 c-12">
                                    <div v-if="entity.estimateType == 1" class="form-control">
                                        <md-autocomplete v-model="vehicleName" @md-selected="getVehicleSelected" :md-options="vehicles" @md-changed="getVehicles"  @md-opened="getVehicles" :class="{'md-invalid': submitted && $v.entity.objectId.$error }">
                                            <label>Phương tiện</label>
                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                <md-highlight-text :md-term="term">{{ item.licensePlate }}</md-highlight-text>
                                            </template>
                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                "{{ term }}" Không tìm thấy!
                                            </template>
                                            <span class="md-error" v-if="submitted && !$v.entity.objectId.isSelected">Vui lòng chọn phương tiện/thiết bị</span>
                                        </md-autocomplete>
                                        <md-button @click="openVehicle()" class="md-icon-button">
                                            <md-icon>manage_search</md-icon>
                                            <md-tooltip>Tìm phương tiện</md-tooltip>
                                        </md-button>
                                    </div>
                                </div>
                            </div>
                            
                            <md-field>
                               <label for="note">Nội dung</label>
                               <md-input name="note" v-model="entity.note"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-datepicker v-model="entity.estimateDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.estimateDate.$error }">
                                <label>Ngày lập</label>
                                <span class="md-error" v-if="submitted && !$v.entity.estimateDate.required">Vui lòng chọn ngày dự toán</span>
                            </md-datepicker>
                            <div style="display:flex;">
                                <md-field>
                                    <label for="amount">Tổng toán</label>
                                    <md-input name="amount" :value="formatNumber(entity.estimateAmount)" ></md-input>
                                </md-field>
                            </div>
                        </div>
                    </div>
                    
                    <div class="form-body form-tab" style="padding: 0;">
                        <div class="row">
                            <div class="col l-12 m-12 c-12">
                                <md-tabs>
                                    <md-tab id="tab-supplies" class="tab-content" md-label="Chi tiết dự toán">
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <div class="tool-bar">
                                                        <md-button @click="addDetailRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                    </div>
                                                    <table class="data-table-2"> 
                                                        <thead> 
                                                            <tr> 
                                                                <th style="width:100px;">#</th>
                                                                <th style="width:120px">Hành động</th>
                                                                <th style="width:20%;">Mã phí</th> 
                                                                <th style="width:20%;">Tên phí</th> 
                                                                <th style="width:17%;">Số tiền</th> 
                                                                <th style="width:15%;">Vat</th> 
                                                                <th style="width:18%;">Tổng tiền</th>
                                                            </tr> 
                                                        </thead> 
                                                        <tbody v-if="loadding == true" style="height: 100px;"> 
                                                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                        </tbody>
                                                        <tbody v-if="loadding == false"> 
                                                            <tr v-for="(item, index) in entity.details" :key="'detail-' + item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                <td class="center">{{index + 1}}</td>
                                                                <td class="grid-action">
                                                                    <a @click="delDetailRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                                </td> 
                                                                <td class="center">
                                                                    <div class="grid-control">
                                                                        <input v-model="item.fee.feeCode" :class="{'is-error': $v.entity.details.$each[index].feeId.$error}" class="form-control" type="text">
                                                                        <md-button @click="openFee(item.id)" class="md-icon-button">
                                                                            <md-icon>manage_search</md-icon>
                                                                            <md-tooltip>Tìm phí</md-tooltip>
                                                                        </md-button>
                                                                    </div>
                                                                </td> 
                                                                <td class="left">
                                                                    {{item.fee.feeName}}
                                                                </td>
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <number @change="changeAmount(item)" v-model="item.estimatePrice" :class="{'is-error': $v.entity.details.$each[index].estimatePrice.$error}" v-bind="currency" class="form-control currency"></number> 
                                                                    </div>
                                                                </td>
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <number @change="changeAmount(item)" v-model="item.estimateVat" v-bind="vatF" class="form-control currency"></number> 
                                                                    </div>
                                                                </td> 
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <number disabled v-model="item.estimateAmount" v-bind="currency" class="form-control currency"></number>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody> 
                                                    </table> 
                                                </div>
                                            </div>
                                        </div>
                                    </md-tab>
                                    <md-tab id="tab-approve" class="tab-content" md-label="Phê duyệt">
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <div class="tool-bar">
                                                        <md-button @click="addApproveRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                    </div>
                                                    <table class="data-table-2"> 
                                                        <thead> 
                                                            <tr> 
                                                                <th style="width:100px;">#</th> 
                                                                <th style="width:20%;">Bộ phận/phòng</th> 
                                                                <th style="width:20%;">Người phê duyệt</th> 
                                                                <th style="width:23%;">Tiêu đề</th>
                                                                <th style="width:15%;">Chức năng</th>
                                                                <th style="width:10%;">Thứ tự</th> 
                                                                <th style="width:150px">Hành động</th> 
                                                            </tr> 
                                                        </thead> 
                                                        <tbody v-if="loadding == true" style="height: 100px;"> 
                                                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                        </tbody>
                                                        <tbody v-if="loadding == false"> 
                                                            <tr v-for="(item, index) in entity.approveList" :key="'approve-' + item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                <td class="center">{{index + 1}}</td>
                                                                <td class="center">
                                                                    <div class="grid-control">
                                                                        <input disabled v-model="item.dept.name" :class="{'is-error': $v.entity.approveList.$each[index].deptId.$error}"  class="form-control" type="text">
                                                                        <md-button @click="openDept(item.id)" class="md-icon-button">
                                                                            <md-icon>manage_search</md-icon>
                                                                            <md-tooltip>Tìm bộ phận</md-tooltip>
                                                                        </md-button>
                                                                    </div>
                                                                </td> 
                                                                <td class="center">
                                                                    <div class="grid-control">
                                                                        <input disabled v-model="item.staff.fullName" :class="{'is-error': $v.entity.approveList.$each[index].staffId.$error}"  class="form-control" type="text">
                                                                        <md-button @click="openApprove(item.id)" class="md-icon-button">
                                                                            <md-icon>manage_search</md-icon>
                                                                            <md-tooltip>Tìm nhân viên</md-tooltip>
                                                                        </md-button>
                                                                    </div>
                                                                </td> 
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <input v-model="item.title" class="form-control" type="text">
                                                                    </div>
                                                                </td>
                                                                <td class="center">
                                                                    <md-field>
                                                                        <md-select v-model="item.isCommenter" name="approveTypes" id="approveTypes">
                                                                            <md-option v-for="obj in approveTypes" :key="'type' + obj.value" :value="obj.value">{{obj.text}}</md-option>
                                                                        </md-select>
                                                                    </md-field>
                                                                </td>
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <input v-model="item.orderSort" class="form-control" type="number" style="text-align: center;">
                                                                    </div>
                                                                </td> 
                                                                <td class="grid-action">
                                                                    <a @click="delApproveRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                                </td> 
                                                            </tr> 
                                                        </tbody> 
                                                    </table> 
                                                </div>
                                            </div>
                                        </div>
                                    </md-tab>
                                    <md-tab id="tab-files" class="tab-content" md-label="File dự toán">
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <upload ref="upload" :updateFileInParent="updateFile"/>
                                                </div>
                                            </div>
                                        </div>
                                    </md-tab>
                                </md-tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <staffList ref="staffList" @close="closeStaff"/>
        <vehicleList ref="vehicleList" @close="closeVehicle"/>
        <approveList ref="approveList" @close="closeApprove"/>
        <deptList ref="deptList" @close="closeDept"/>
        <feeList ref="feeList" @close="closeFee"/>
   </div>
</template>
<script>
    import estimateService from '../../../api/estimateService';
    import staffService from '../../../api/staffService';
    import approveSettingService from '../../../api/approveSettingService';
    import vehicleService from '../../../api/vehicleService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '../../../mixins'; 
    import staffList from '../../../components/popup/_StaffList.vue';
    import approveList from '../../../components/popup/_StaffList.vue';
    import vehicleList from '../../../components/popup/_VehicleList.vue';
    import deptList from '../../../components/popup/_DepartmentList.vue';
    import upload from '../../../components/Upload.vue';
    import feeList from '../../../components/popup/_FeeList.vue';

    export default ({
        components: {
            staffList,
            vehicleList,
            deptList,
            approveList,
            upload,
            feeList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật dự toán'
        },
        data() {
            return {
                title: '',
                id: 0,
                submitted: false,
                loadding: false,
                entity: { id: 0, estimateType: 1, objectId: 0, files: [], approveList: [], details: [], staffId: '', staff: {}, estimateCode: '', estimateDate: null, note: '', status: 0, estimateAmount: 0 },
                staffName: '',
                staffs: [],
                approveTypes: common.approveTypes,
                approve: { id: 'id_' + common.getFakeId(), status: 1, isCommenter: false, dept: { code: '', name: '' }, deptId: 0, staff: { id: 0, fullName: '' }, staffId: 0, title: 'Người đề nghị', note: '', orderSort: 1, objectType: 2},
                detail: { id: 'id_' + common.getFakeId(), feeId: 0, fee: { feeCode: '', feeName: '' }, estimateAmount: 0, estimateVat: 0, estimatePrice: 0, estimateNote: '' },
                numberF: common.numberF,
                currency: common.currencyF,
                vatF: common.vatF,
                vehicleName: '',
                vehicles: [],
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
               this.title = 'Cập nhật dự toán';
               this.getById();
            }
            else{
               this.title = 'Thêm mới dự toán';
               this.entity.estimateDate = common.dateNow;
               this.getByAccount();
               this.entity.approveList.push(this.approve);
               this.getApproveSetting();
               this.entity.details.push(this.detail);
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            calSummary(){
                this.entity.estimateAmount = 0;
                for (var i = 0; i < this.entity.details.length; i++) {
                    this.entity.estimateAmount = parseFloat(this.entity.estimateAmount) + parseFloat(this.entity.details[i].estimateAmount);
                }
            },

            changeAmount(item){
                let vat = (parseFloat(item.estimatePrice) * (parseFloat(item.estimateVat) / 100));
                item.estimateAmount = parseFloat(parseFloat(item.estimatePrice) + vat);
                this.calSummary();
            },

            updateFile(files){
                this.entity.files = files;
            },

            closeFee(item){
                const selected = this.entity.details.findIndex(x => x.id == this.selectedId);
                this.entity.details[selected].fee = item;
                this.entity.details[selected].feeId = item.id;
                this.$refs.feeList.close();
                this.selectedId = '';
            },

            openFee(id){
                this.selectedId = id;
                this.$refs.feeList.open();
            },

            //Vehicle
            closeVehicle(item){
                this.vehicleName = item.licensePlate;
                this.entity.objectId = item.id;
                this.$refs.vehicleList.close();
            },

            getVehicleSelected(val){
                this.entity.objectId = val.id;
                this.vehicleName = val.licensePlate;
            },

            openVehicle(){
                this.$refs.vehicleList.open();
            },

            getVehicles(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, licensePlate:  searchTerm };
                vehicleService.getVehicles(search).then((response) => {
                    if(response.statusCode == 200){
                        this.vehicles = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Detail
            delDetailRow(item){
                const index = this.entity.details.findIndex(x => x.id == item.id);
                this.entity.details.splice(index, 1);
                this.calSummary();
            },

            addDetailRow(){
                let item =  { id: 'id_' + common.getFakeId(), feeId: 0, fee: { feeCode: '', feeName: '' }, amount: 0, vat: 0, pay: 0, note: '' };
                this.entity.details.push(item);
            },

            getCode(staffId){
                this.setLoading(true);
                estimateService.getCode(staffId).then((response) => {
                    if(response.statusCode == 200){
                        this.entity.estimateCode = response.data.code;
                        this.entity.estimateNumber = response.data.number;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Get Approve Setting
            getApproveSetting(){
                approveSettingService.getSetting(common.approveType.requestRepair).then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null && response.data.length > 0)
                        {
                            for (let i = 0; i < response.data.length; i++) {
                                let setting = response.data[i];
                                let approve = { id: 'id_' + common.getFakeId() + i, status: 1, dept: { code: '', name: '' }, staff: { id: 0, code: '', fullName: '' }, deptId: 0, staffId: 0, title: '', note: '', orderSort: 1, typeCode: String(common.approveType.requestRepair)};
                                approve.id = 'id_' + common.getFakeId() + i;
                                approve.staffId = setting.staffId;
                                approve.deptId = setting.deptId;
                                approve.dept.code = setting.department.code;
                                approve.dept.name = setting.department.name;
                                approve.staff.code = setting.staff.code;
                                approve.staff.fullName = setting.staff.fullName;
                                approve.title = setting.title;
                                approve.orderSort = setting.orderSort + 1;
                                
                                this.entity.approveList.push(approve);
                            }
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //APPROVE
            orderApprove(){
                if(this.entity.approveList != null){
                    for(let i = 0; i < this.entity.approveList.length; i ++){
                        this.entity.approveList[i].orderSort = i + 1;
                    }
                }
            },

            delApproveRow(item){
                const index = this.entity.approveList.findIndex(x => x.id == item.id);
                this.entity.approveList.splice(index, 1);
                this.orderApprove();
            },

            addApproveRow(){
                let order = 1;
                if(this.entity.approveList != null){
                    order = this.entity.approveList.length + 1;
                }
                let item = { id: 'id_' + common.getFakeId(), status: 1, isCommenter: false, dept: { code: '', name: '' }, staff: { id: 0, fullName: '' }, deptId: 0, staffId: 0, title: '', orderSort: order, objectType: 2 };
                this.entity.approveList.push(item);
            },

            closeApprove(item){
                const selected = this.entity.approveList.findIndex(x => x.id == this.selectedId);
                this.entity.approveList[selected].staff = item;
                this.entity.approveList[selected].staffId = item.id;
                this.$refs.approveList.close();
                this.selectedId = '';
            },

            openApprove(id){
                this.selectedId = id;
                const index = this.entity.approveList.findIndex(x => x.id == id);
                this.deptId = this.entity.approveList[index].deptId;
                this.$refs.approveList.open(this.deptId);
            },

            closeDept(item){
                const selected = this.entity.approveList.findIndex(x => x.id == this.selectedId);
                this.entity.approveList[selected].dept = item;
                this.entity.approveList[selected].title = item.name;
                this.entity.approveList[selected].deptId = item.id;
                if(item.managerId > 0){
                    this.entity.approveList[selected].staff = item.manager;
                    this.entity.approveList[selected].staffId = item.managerId;
                }
                this.$refs.deptList.close();
                this.selectedId = '';
            },

            openDept(id){
                this.selectedId = id;
                this.$refs.deptList.open();
            },

            //Staff
            closeStaff(item){
                this.staffName = item.fullName;
                this.entity.staffId = item.id;
                this.entity.deptId = item.departmentId;
                this.entity.approveList[0].staffId = item.id;
                this.entity.approveList[0].staff = item;
                this.entity.approveList[0].deptId = item.department.id;
                this.entity.approveList[0].dept = item.department;
                this.getCode(item.id);
                this.$refs.staffList.close();
            },

            getStaffSelected(val){
                this.entity.staffId = val.id;
                this.entity.deptId = val.departmentId;
                this.staffName = val.fullName;
                this.getCode(val.id);
                this.entity.approveList[0].staffId = val.id;
                this.entity.approveList[0].staff = val;
                this.entity.approveList[0].deptId = val.department.id;
                this.entity.approveList[0].dept = val.department;
            },

            openStaff(){
                this.$refs.staffList.open();
            },

            getStaffs(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.staffs = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            submit(status){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.entity.details.length > 0){
                    this.entity.details = this.entity.details.filter(item => !(item.vehicleId == 0));
                    this.entity.details.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                if(this.entity.details.length == 0){
                    messageBox.showWarning("Vui lòng nhập thêm chi phí");
                    return;
                }
                if(this.entity.approveList.length > 0){
                    this.entity.approveList = this.entity.approveList.filter(item => !(item.deptId == 0));
                    this.entity.approveList.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                if(this.entity.approveList.length <= 1){
                    messageBox.showWarning("Vui lòng chọn thêm phê duyệt");
                    return;
                }
                this.entity.status = status;
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                estimateService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        this.$router.push('/estimate');
                    }
                    else{
                        this.entity.status = 0;
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                estimateService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/estimate');
                    }
                    else{
                        this.entity.status = 0;
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                estimateService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.staffName = this.entity.staff.fullName;
                        this.vehicleName = this.entity.vehicle.licensePlate;
                        this.$refs.upload.loadData(this.entity.files);
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getByAccount(){
                this.setLoading(true);
                staffService.getByAccount().then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null){
                            this.entity.staff = response.data;
                            this.entity.staffId = response.data.id;
                            this.entity.deptId = response.data.departmentId;
                            this.staffName = response.data.fullName;
                            this.currentUser = response.data;
                            this.approve.staffId = response.data.id;
                            this.approve.staff = response.data;
                            this.approve.deptId = response.data.department.id;
                            this.approve.dept = response.data.department;
                            this.getCode(this.entity.staffId);
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            back(){
                this.$router.push('/estimate');
            }
        },
        watch: { 
            staffName: function (val) { 
                if(val == ''){
                    this.entity.staffId = 0;
                }
            },
            vehicleName: function (val) { 
                if(val == ''){
                    this.entity.objectId = 0;
                }
            },
        },
        validations: {
            entity: {
                staffId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                estimateType: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                objectId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                estimateCode: { required },
                estimateDate: { required },
                details: {
                    $each: {
                        feeId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                        estimatePrice: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                    }
                },
                approveList: {
                    $each: {
                        deptId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                        staffId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                    }
                },
            }
        }
     })

</script>

<style scoped>
    .approve{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .approve .md-chip{
        height: 25px !important;
        line-height: 25px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
    }

    .approve .md-chip i {
        font-size: 20px;
        padding-right: 5px;
    }
</style>
